/**
 * Combined by jsDelivr.
 * Original files:
 * - /npm/foundation-sites@6.6.1/dist/css/foundation-float.min.css
 * - /npm/hover.css@2.3.2/css/hover.min.css
 * - /npm/plyr@3.5.6/dist/plyr.min.css
 * - /npm/aos@2.3.4/dist/aos.min.css
 *
 * Do NOT use SRI with dynamically generated files! More information: https://www.jsdelivr.com/using-sri-with-dynamic-files
 */
